img {
  padding: 40px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

body {
  margin: 0px;
}
